import About from "./components/About";
import Header from "./components/Header";
import Layout from "./components/Layout";
import Navbar from "./components/Navbar";

function App() {
  return (
    <Layout>
      <Navbar />
      <Header />
      <About />
    </Layout>
  );
}

export default App;
