import Container from "./Container";

const About = () => {
  return (
    <div className="bg-gradient-to-r from-gray-500 to-gray-800" id="about-me">
      <Container>
        <div className="flex flex-wrap px-20 items-center pt-16 pb-18">
          <div className="w-6/12 relative">
            <img src="/assets/reihan.jpeg" className="w-[250px] h-[300px] rounded-lg object-cover object-center drop-shadow-2xl border border-4 absolute bottom-8 left-28" />
            <div className="border border-8 border-green-600 w-[250px] h-[300px] ml-auto mr-36 rounded-lg"></div>
          </div>
          <div className="w-6/12">
            <h1 className="text-3xl text-white font-bold">About Me</h1>
            <div className="border-divider"></div>
            <div className="py-8">
              <p className="text-white/60">Web Developer with one year experience as Full Stack Developer. Problem solving ability. Skilled in analyzing data. Active in organization since high school, good communication skills.</p>
            </div>
            <div className="flex flex-wrap py-2 items-center space-x-4">
              <button className="border border-2 round hover:border-green-600 px-6 py-2 text-sm font-semibold text-white rounded-md">Contact Me</button>
              <button className="bg-green-600 hover:bg-white/80 hover:text-gray-600 font-semibold w-100 px-10 py-2 text-sm text-white rounded-md">Resume</button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default About;
