const Navbar = () => {
  return (
    <nav class="px-2 sm:px-24 py-8 rounded">
      <div class="container flex flex-wrap items-center mx-auto">
        <a href="https://flowbite.com" class="flex items-center">
          <span class="self-center text-white text-3xl font-semibold whitespace-nowrap">
            Reihan<b className="text-green-600">Praja</b>
          </span>
        </a>
        <button
          data-collapse-toggle="mobile-menu"
          type="button"
          class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
          </svg>
          <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div class="hidden w-full md:block md:w-auto ml-auto" id="mobile-menu">
          <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium text-white">
            <li>
              <a href="#" class="block md:text-lg py-2 pr-4 pl-3 bg-green-600 rounded md:bg-transparent md:text-green-600 md:p-0 dark:text-white" ariaCurrent="page">
                Home
              </a>
            </li>
            <li>
              <a href="#about-me" class="block md:text-lg py-2 pr-4 pl-3 text-white rounded md:p-0" ariaCurrent="page">
                About Me
              </a>
            </li>
            <li>
              <a href="#" class="block md:text-lg py-2 pr-4 pl-3 text-white rounded md:p-0" ariaCurrent="page">
                Resume
              </a>
            </li>
            <li>
              <a href="#" class="block md:text-lg py-2 pr-4 pl-3 text-white rounded md:p-0" ariaCurrent="page">
                Testimonial
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
